@import "./src/styles/variables";

.boxContent{
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: .35rem;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid #e8ebed;
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 5px solid #22222275;
  }
  &::-webkit-scrollbar-track {
    position: absolute;
    right: -3rem;
    top: -50rem;
    background: transparent;
  }
  max-height: 260px;
}
.UserName {
  composes: mr-3 from global;
  :global(.top-bar-theme-light) & {
    color: inherit;
  }
  :global(.top-bar-theme-dark) & {
    color: white;
  }
}
.Switch {
  color: $primary;
  cursor: pointer;
}
.BookABooth {
  background-color: $primary;
  border-radius: 99px;
  width: 9rem;
  height: 2rem;
  outline: none;
  border: none;
  color: white;
  margin-left: 2rem;
}
.searchIcon {
  text-align: right;
  position: relative;
  min-width: 14rem;
  height: 42px;
}
.searchItemInput {
  width: 100%;
  padding: 8px 30px;
  border-radius: 99px;
  border: none;
  outline: none;
  box-shadow: 0 1px 5px 0px black;
}
.searchItemIcon {
  font-size: 32px;
  position: absolute;
  right: 5px;
  bottom: 8px;
}
.searchIcon:hover {
  color: $primary; 
  cursor: pointer;
}
.listItem {
  color: $primary;
  margin-left: 2rem;
  cursor: pointer;
  overflow: hidden;
  svg {
    transform: translateY(15%) scale(1.2);
  }
}
.AdminTools {
  margin-right: 1rem;
  color: $internal-gray;
  cursor: pointer;
}
.menuSelector {
  width: 344px;
  display: flex;
  justify-content: space-between;
}
.ItemIcon {
  margin-right: 5px;
}
.ItemLink {
  text-decoration: none;
  color: #ee6657;
}
.ItemLink:hover {
  color: #e92f1b;
}
.iconInfomation {
  cursor: pointer;
}
.menuSelectorItem {
  padding: 15px;
  width: 50%;
  font-size: 16px;
  text-align: center;
}
.ItemSelector:hover {
  color: #e92f1b;
}
.ItemSelector {
  margin-top: 30px;
  color: #ee6657;
}
.Badge {
  composes: ml-2 from global;
  font-weight: 500;
  font-size: 0.7em;
  padding-top: 0.3rem;
  padding-bottom: 0.25rem;
}

.Icon {
  width: 3rem;
  height: 3rem;
  background-color: #ddd;
  border-radius: 50%;
  composes: d-flex align-items-end justify-content-center from global;
  overflow: hidden;
  margin-left: 2rem;
  svg {
    height: 85%;
  }
  cursor: pointer;
}
.Dropdown {
  margin-top: .65rem !important;
  left: auto;
  padding-top: 0;
  padding-bottom: 0;
  border-color: #b8b8b8;
  box-shadow: $internal-box-shadow;
  .DropdownBg {
    padding: .5rem 0;
    background-color: #fff;
    border-radius: .375rem;
  }
  .DropdownHeader,
  .DropdownItem {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  &::after {
    content: "";
    position: absolute;
    right: 0.95rem;
    left: auto;
    top: -.35rem;
    z-index: -1;
    width: 1rem;
    height: 1rem;
    // background: var(--falcon-card-bg);
    background: inherit;
    transform: rotate(225deg);
    border-radius: 0.125rem;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: #b8b8b8;
    // border-color: rgba(0, 0, 0, 0.175);
    box-shadow: $internal-box-shadow-sm;
  }
}
.DropdownCart {
  min-width: calc(18.5rem + 2rem);
  max-width: calc(18.5rem + 2rem);
  margin-top: .65rem !important;
  margin-left: 2rem !important;
  left: auto;
  border-radius: .5rem;
  font-size: 1rem;
  .DropdownCartEventName {
    font-size: 1.125rem;
    white-space: normal !important;
    word-wrap: break-word;
  }
  .DropdownCartItem {
    width: 100%;
    margin-top: .5rem;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .DropdownCartDivider {
    width: calc(100% - 3rem);
    margin-top: 0 !important;
    margin-left: 1.5rem;
    margin-bottom: 1rem !important;
  }
  .DropdownCartButton,
  .DropdownCartTimeExpiried {
    padding: 0rem 1.5rem;
    margin-bottom: .5rem;
    .DropdownCartText {
      text-align: center;
      font-size: .875rem;
    }
  }
  .DropdownClearCart {
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }
}
.ExhibitorItem {
  composes: ItemWithSelectedDot from "../../styles/common.module.scss";
  color: $body-color;
  padding: 0.7rem 1rem {
    left: 2rem;
  }
  cursor: pointer;
  &:hover {
    background-color: #e7eaec;
  }
}
.ExhibitorItemSelected {
  composes: ItemSelected from "../../styles/common.module.scss";
}
.Unavailable {
  color: #aaa;
}
.SwitchBtn {
  color: $primary;
  cursor: pointer;
  font-weight: 500;
  margin-left: 0.4rem;
}
.Toggle {
  composes: d-flex align-items-center mr-3 from global;
  position: relative;
  margin-right: 0.5rem !important;
  z-index: 0;
  &:before {
    position: absolute;
    top: -2px;
    left: -4px;
    right: -4px;
    bottom: -2px;
    content: "";
    background-color: #e7eaec;
    border-radius: 2px;
    opacity: 0;
    z-index: -1;
  }
  // &:hover:before {
  //   opacity: 1;
  // }
}
.StatusIndicator {
  line-height: 1rem;
  vertical-align: middle;
  text-align: center;
  margin-right: 0.7rem;
}
