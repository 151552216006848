.Wrapper {
  position: absolute;
  &[data-debug="false"] {
    height: 0;
    width: 0;
    overflow: hidden;
  }
}
.Page {
  background: white;
  height: 21cm;
  width: 29.7cm;
  display: block;
  margin: 0;
  // @media screen {
  //   box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
  // }
  padding: 15mm;
  &,
  * {
    font-family: Arial, sans-serif;
  }
  p,
  span,
  th,
  td {
    font-size: 9pt;
  }
  p {
    margin-bottom: 0.85em;
  }
}
