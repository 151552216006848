.LoginPage {
  // height: 100%;
  background-color: #fff;
  // padding: 4rem 0;
  position: relative;
  // padding-top: 11.4rem;
  // padding-bottom: 8rem;
  // min-height: 100vh;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // &,
  // & > div {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }
  // > div {
  //   flex-direction: column;
  //   //flex-basis: 45%;
  //   > :nth-child(1) {
  //     flex-basis: 45%;
  //     width: percentage(2/3);
  //     svg {
  //       width: 100%;
  //       transform: scale(2.16) translateY(2rem);
  //       transform-origin: 40% bottom;
  //     }

  //     align-self: start;
  //     padding-left: 1rem;
  //     padding-bottom: 2rem;
  //   }
  //   > :nth-child(2) {
  //     flex-basis: 55%;
  //   }
  // }
}

// .Content {
//   overflow: scroll;
// }
