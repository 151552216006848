@import "./src/styles/variables";

.NavBar {
  overflow-y: visible !important;
  height: $external-topbar-height;
  background: #ffffff;
  // box-shadow: 0 2px 6px 0 rgba(156, 156, 156, 0.5);
  > :global(.navbar-brand) {
    flex-grow: 1;
  }
  display: flex;
  justify-content: space-between;
  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100%);
    height: $external-topbar-height;
    
    box-shadow: 0 0.5rem 0.5rem -0.5rem #9c9c9c80 !important;
  }
}
.supportLine {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #D9D9D9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0;
}
.supportLineLeft {
  width: 70%;
  display: flex;
  justify-content: left;
  margin: 0;
  padding: 0;
}
.menuSupportLine {
  width: 80%;
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.supportLineRight {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}
.supportLineItem {
  margin-right: 3rem;
}
.NavBarDark {
  background: #414042;
}
.TopBarMenu {
  margin-right: 1rem;
}
.Logo {
  margin-left: 60px;
}
.boxMenuTopBar {
  width: 100%;
  padding: 0 3rem;
}
.RightIcon {
  width: 50%;
  margin-right: 3.2rem;
}
.RightIconUserNotLogin {
  width: 50%;
  margin-right: 3.5rem;
}
.topBarMenuItem {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: right;
}
.btnHome {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 18px;
  min-width: 80px;
  margin-right: 10px;
  cursor: pointer;
}
.btnHomeItem:hover {
  color: #FF671D;
  box-shadow: 0 3px #FF671D;
}
// @media screen and (max-width: 980px) { 
//   .supportLine {
//     display: flex;
//     justify-content: center;
//   }
//   .supportLineLeft {
//     display: none;
//     width: 0;
//   }
//   .supportLineRight {
//     width: 100%;
//     justify-content: center;
//     font-size: 22px;
//   }
//   .topBarMenuItem {
//     display: none;
//   }
//   .RightIcon {
//     width: auto;
//     width: 80%;
//     margin: 0;
//   }
//   .Logo {
//     margin: 0;
//   }
// }