.NavBar {
  overflow-y: visible !important;
  height: 6.25rem;
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(156, 156, 156, 0.5);
  > :global(.navbar-brand) {
    flex-grow: 1;
  }
}
.NavBarDark {
  background: #414042;
}
.TopBarMenu {
  margin-right: 1rem;
}
.HamburgerIcon {
  padding: 1rem;
  line-height: 1;
}
.Logo {
  margin-left: 80px;
}
.RightIcon {
  margin-right: 3rem;
}
