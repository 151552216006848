$body-color: #404142;
$primary: #FF671D;
$secondary: #414042;
$light: #f3f4f5;
$green: #05b57d;
$grey: #f2f2f2;
$primary-hover: #bd0d63;
$font-family-sans-serif: "General Sans", -apple-system, BlinkMacSystemFont,
  "Segoe UI", General Sans, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-secondary: "General Sans";

$btn-disabled-opacity: 0.45;

$internal-topbar-height: 3.7125rem;
$external-topbar-height: 4.8125rem;
$topbar-supportline-height: 2rem;

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 900px,
  lg: 1200px,
  xl: 1536px,
  hd: 1920px
);

$font-size-login-form: 2.8125rem;

$font-size-base: 0.88rem;

$input-btn-focus-box-shadow: none !important;
$btn-focus-box-shadow: none !important;

$input-btn-padding-y: 0.55rem;
$input-btn-padding-x: 1rem;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$mobile-input-border-radius: calc(
  #{$input-line-height * 0.5em} + #{$input-padding-y} + #{$input-height-border}
);

@mixin iphone() {
  @media only screen and (max-width: 768px) and (max-height: 897px) and (orientation: portrait) {
    @content;
  }
}
@mixin non-iphone() {
  @media only screen and (min-width: 415px) and (min-height: 897px) {
    @content;
  }
}

@mixin no-link() {
  &,
  &:hover,
  &:focus {
    color: $primary;
  }
  text-decoration: none;
  @include non-iphone {
    &:hover,
    &:focus {
      color: $primary;
    }
  }
  &.no-hover:hover {
    color: $primary;
  }
}

$box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);

$internal-white: #fff;
$internal-bg: #ffffff;
$internal-lighter-bg: #ffffff;

$internal-gray-light: #9da9bb;
$internal-gray: rgba(0, 0, 0, 0.54);
$internal-box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
$internal-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
$internal-btn-box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.08);

@mixin scroll-bar() {
  &::-webkit-scrollbar {
    width: .5rem;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid #66666675;
    // border-radius: 10rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 5px solid #22222275;
  }
  &::-webkit-scrollbar-track {
    position: absolute;
    right: -3rem;
    top: -50rem;
    background: transparent;
  }
}
$scroll-bar: scroll-bar();

:export {
  primary: $primary;
  internalBg: $internal-bg;
  scrollBar: $scroll-bar;
  primaryHover: $primary-hover;
}