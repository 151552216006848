@import "./styles/variables";

html,
body,
:global(#root),
.App {
  // height: 100%;
  height: fit-content;
  min-height: 100vh;
  // overflow: inherit;
  // overflow-x: visible;
  @include iphone {
    width: 100vw;
    overflow-x: hidden;
  }
}

.App {
  > *:not[non-scroll] {
    overflow-y: scroll;
  }
  padding-top: $external-topbar-height + $topbar-supportline-height;
  @include iphone {
    padding-top: 60px;
  }
  &.Fullscreen {
    padding-top: 0;
  }
  &.InternalApp {
    // margin-left: 12.625rem;
    @include iphone {
      margin-left: 0rem !important;
    }
    padding-top: 0 !important;
    background: #fff !important;
  }
}