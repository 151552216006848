@import "./src/styles/variables";

.NavBar {
  padding: 0 1rem;
  overflow-y: visible !important;
  height: $internal-topbar-height;
  background: #fff;
  box-shadow: 0px 0px 4px 3px #b7b7b769;
  // box-shadow: 0 2px 6px 0 rgba(156, 156, 156, 0.5);
  > :global(.navbar-brand) {
    flex-grow: 1;
  }
  &.NavBarShadow {
    &::after {
      content: "";
      z-index: -1;
      position: absolute;
      right: 0;
      bottom: 0;
      width: calc(100% - (12.625rem + 2rem));
      height: $internal-topbar-height;
      
      box-shadow: 0 0.5rem 0.5rem -0.5rem #9c9c9c80 !important;
    }
  }
}
.NavBarDark {
  background: #414042;
}
.TopBarMenu {
  margin-right: 1rem;
}
.Logo {
  margin-left: 1rem;
  height: 2.5rem;
  min-height: 2.5rem;
}
.LeftSection {
  display: block;
  .HamburgerIcon {
      transform: scale(.75);
      margin-right: 1rem;
      cursor: pointer;
  }
}
.RightIcon {
}
  
.MenuToggle {
    text-align: left;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
    width: 100%;
}