@import "src/styles/variables";

$exhibitor_finalized: (
  "A": -10%,
  "B": 0,
  "C": 7%,
  "D": 15%
);
@mixin fill($color) {
  background-color: $color;
  &.Text {
    color: $color;
  }
  border-color: $color;
  svg {
    fill: $color;
  }
}
.Badge {
  cursor: pointer;
}
.Inactivated {
  @include fill(#b3b3b3);
  color: #ececec;
}
.Draft {
  @include fill(#b3b3b3);
}
.Standard {
  @include fill(lighten($primary, 10%));
}
@each $tier, $percentage in $exhibitor_finalized {
  .Finalized#{$tier} {
    @if ($percentage<0) {
      @include fill(lighten($primary, -$percentage));
    } @else {
      @include fill(darken($primary, $percentage));
    }
  }
}
.Text {
  background-color: transparent;
}
