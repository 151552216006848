@import "./src/styles/variables";

.SideBarSpacing {
  display: inline;
  height: 100vh;
  margin-left: 12.625rem;
}

.SideBar {
  height: 90%;
  overflow-y: scroll;
  position: fixed;
  display: inline-block;
  z-index: 1019;
  margin-top: 5px !important;
  top: $internal-topbar-height;
  width: 100%;
  // height: calc(100vh - #{$internal-topbar-height});
  max-width: 12.625rem;
  // max-width: 15.625rem;
  padding: .5rem 0rem 1rem 0rem;
  margin: 0;
  background: $internal-bg;
  
  // Custom Sibebar's scrollbar
  overflow: scroll;
  overflow-y: overlay;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
    width: .35rem;
    max-height: 50vh;
  }
  &:hover::-webkit-scrollbar {
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid rgba(0, 0, 0, .08);
    border-radius: 10rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 5px solid rgba(0, 0, 0, .1);
  }
  &::-webkit-scrollbar-track {
    position: absolute;
    right: -3rem;
    top: -50rem;
    background: transparent;
  }

  .VerticalLabel {
    position: relative;
    width: 100%;
    min-height: 1.125rem;
    margin: 1rem 0 .5rem;
    padding: 0;
    color: $internal-gray;
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 50%;
      right: 0;
      width: 100%;
      height: 1px;
      // margin-top: .5625rem;
      // background-color: rgba(0, 0, 0, .08);
    }
    .Label {
      background-color: $internal-bg;
      padding-right: 1rem;
      // color: $internal-gray-light;
      font-size: $font-size-base;
      font-weight: 600;
    }
  }

  .Items {
    .DropdownItem > div:first-child,
    & > div:not(.DropdownItem) {
      // composes: d-flex align-items-center;
      display: flex;
      // flex-direction: column !important;
      align-items: center;
      padding: .35rem 0;
      cursor: pointer;
      color: $internal-gray;
      & > span {
        width: 2.5rem;
        min-width: 1.5rem;
      }
      &:hover {
        color: $secondary;
      }
    }
    .DropdownItem > div:first-child {
      position: relative;
      padding-right: 1rem;
      &::after {
        content: "";
        display: block;
        position: absolute;
        right: 5px;
        top: 50%;
        height: .4rem;
        width: .4rem;
        border-right: 1px solid $internal-gray;
        border-bottom: 1px solid $internal-gray;
        transform: translateY(-50%) rotate(225deg);
        transition: all .2s ease-in-out;
        transform-origin: center;
        transition-property: transform, border-color;
      }
      &:hover::after {
        border-color: $secondary !important;
      }
    }
    .DropdownItemShow {
      color: $secondary !important;
      &::after {
        border-color: $secondary !important;
        transform: translateY(-50%) rotate(405deg) !important;
      }
    }
    .DropdownMenu {
      & > div, a {
        cursor: pointer;
        color: $internal-gray;
        padding: .2rem .2rem .2rem 2.5rem;
        &:hover {
          color: $secondary;
        }
      }
    }
    .ItemActive {
      color: $primary !important;
    }
  }
}

.NavBar {
  padding: 0 1rem;
  overflow-y: visible !important;
  height: $internal-topbar-height;
  background: #fff;
  // box-shadow: 0 2px 6px 0 rgba(156, 156, 156, 0.5);
  > :global(.navbar-brand) {
    flex-grow: 1;
  }
  &.NavBarShadow {
    box-shadow: 0 0.5rem 0.5rem -0.5rem #9c9c9c80 !important;
  }
}
.NavBarDark {
  background: $secondary;
}
.TopBarMenu {
  margin-right: 1rem;
}
.Logo {
  margin-left: 1rem;
  height: 2.5rem;
  min-height: 2.5rem;
}
.LeftSection {
  display: block;
  .HamburgerIcon {
      transform: scale(.75);
      margin-right: 1rem;
      cursor: pointer;
  }
}
.RightIcon {
}
  
.MenuToggle {
    text-align: left;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
    width: 100%;
}