@import "./src/styles/variables";

.NavPills {
    margin-top: .5rem;
    background-color: $internal-lighter-bg;
    border-radius: .25rem !important;
    border: 1px solid rgba(0, 0, 0, 0.08);
    color: $internal-gray;

    .NavPillsItem {
        width: fit-content;
        min-width: fit-content;

        .ButtonGroup {
            width: fit-content;
        }
    }

    button {
        padding: .25rem .75rem;
        border-radius: .2rem;
        color: $internal-gray;
    }
    
    .ItemActive {
        color: $primary;
        background-color: $internal-white;
        box-shadow: $internal-btn-box-shadow;
        pointer-events: none;
    }
}