.Selector {
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: .75rem .75rem;
    cursor: pointer !important;
    &:read-only:not(:disabled) {
      background-color: #fff;
    }
    &:focus {
      //border-color: #ced4da;
    }
  }
  
  .SelectorFocused {
    border-color: #f679b7;
  }