.ResetPasswordPage {
  background-color: #efefef;
  padding-top: 5%;
  padding-bottom: 5%;
}

.Form {
  background-color: white;
  padding: 0px !important;
}

.FormText {
  composes: pr-2 from global;
  text-align: right;
}

.Logo {
  position: absolute;
  top: 25px;
  left: 25px;
  width: 34%;
  cursor: pointer;
}

.Content {
  overflow: scroll;
}
