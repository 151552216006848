@import "src/styles/variables";
.MenuItems {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1;
  display: flex;
  @include iphone {
  position: relative;
  list-style: none;
  padding: 0 {
    bottom: 1rem;
  }
  margin: 0;
  z-index: 1;
  display: block;
  }
}
.MenuItem a{
  color: $secondary;
}

.MenuItem {
  // padding: 1rem 1.25rem;
  margin-right: 2rem;
  @include iphone {
    padding: 1rem 1.25rem;
    font-size: 1.2rem;
    min-width: unset;
    padding: {
      top: 0.65rem;
      bottom: 0.65rem;
    }
    text-transform: uppercase;
    color: #444;
  }
  color: #333;
  .MenuThemeDark & {
    color: #ddd;
  }
  .Active {
    color: $primary;
    box-shadow: 0 3px #FF671D;
    font-weight: 500;
    .MenuThemeLight & {
      color: $primary;
    }
    .MenuThemeDark & {
      color: lighten($primary, 5%);
    }
  }
  &.Child {
    font-size: 0.85em;
    padding-top: 0.85em;
  }
  &.Disabled {
    opacity: 0.45;
    &,
    a {
      pointer-events: none;
    }
  }
}
.MenuItem:nth-child(4) {
  min-width: 80px;
}