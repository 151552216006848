@import "src/styles/variables";

.NavBarBase {
  position: relative;
}
.NavBar {
  composes: d-flex justify-content-between align-items-center from global;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 10;
  box-shadow: 0px -1px 6px 0px #999;
  .Logo {
    height: 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.Hamburger {
  height: 60px;
  width: 60px;
  svg {
    width: percentage(5/12);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  .NavBarBase & {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
.User {
  z-index: 999999;
  margin-left: 1rem;
  padding: 1rem {
    top: 1rem;
    left: 0;
    bottom: 1rem;
  }
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  svg {
    height: calc(100% - 0.5rem);
    margin-top: 0.5rem;
  }
}
.ListOuter {
  position: relative;
  height: 100%;
}
.List {
  width: 80vw;
  display: flex;
  flex-direction: column;
}
.Logout {
  composes: MenuItem from "../Menu.module.scss";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: #{0.65 * 2}rem;
}
.ExhOuter {
  padding: 10px 15px;
}
.ExhCurrent {
  font-size: 0.9em;
}
.Exh {
  width: 100%;
  padding: 1rem {
    bottom: 2rem;
  }
  font-size: 1.05rem;
}
.ExhItem {
  composes: pl-3 py-2 from global;
  composes: ItemWithSelectedDot from "../../../styles/common.module.scss";
  &.ExhSelected {
    //font-weight: 600;
    &:before {
      left: 0;
    }
  }
  &.ExhAdd {
    color: $green;
    svg {
      position: absolute;
      top: 0.55em;
      left: -0.4em;
      display: block;
      font-size: 1.2em;
    }
  }
  &.ExhUnavailable {
    color: #aaa;
  }
}
.ExhAdd {
  composes: no-link d-block from global;
}
.ExhSelected {
  composes: ItemSelected from "../../../styles/common.module.scss";
}
