.LoadingAnimationPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: #ffffffa8;
  z-index: 9000;
  > div {
    height: 100%;
  }

  transition: opacity 125ms ease-in-out;
}
