@import "../../styles/variables";

.ExhibitorDetail {
  composes: pt-5 from global;
  position: relative;
}
.EditPanel {
//   padding-left: 4rem;
  .External & {
    > div {
      display: flex;
      flex-wrap: wrap;
      > div {
        flex-basis: 50%;
        padding-right: 1rem;
      }
    }
  }
}
.UserPhoto {
    height: 200px;
    width: 200px;
    border-radius: 100px;
    background-color: rgb(152, 152, 152);
}
.EditField {
  position: relative;
  padding-left: 0.9rem;
  display: table;
  width: 100%;  

  .EditFieldLabel,
  .EditFieldControl {
    display: table-cell;
  }
  .EditFieldLabel {
    white-space: nowrap;
  }
  .EditFieldControl {
    width: 99%;
  }

  .EditFieldButton {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    
  }
  &:hover,
  &.Toggle {
    .EditFieldButton {
      display: inline-block;
      opacity: 1;
    }
  }
}
.EditFieldButton {
  composes: fa-2x from global;
  position: absolute;
  left: -1.4rem;
  top: 50%;
  transform: translateY(-50%);
  // margin-top: -1rem;  
  cursor: pointer;
  font-size: 140%;

  color: lighten($secondary, 25%);
  &:hover {
    color: lighten($secondary, 18%);
  }
  .Toggle &,
  .Toggle & &:hover {
    color: $primary;
  }
}

$control-spacing: -0.4rem;
.EditFieldControl {
  :global(.form-control) {
    margin-top: $control-spacing;
    margin-bottom: $control-spacing;
  }
}

.EditPanelOuter {
  padding-bottom: 10px;
}
.BottomPanel {
  composes: p-4 from global;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 6px 0 rgba(156, 156, 156, 0.5);
}
