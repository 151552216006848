.AlertDismissible {
  font-size: 0.85em;
  border-radius: 0;
  p {
    margin-bottom: 0;
  }
  button:global(.close) {
    padding-top: 0.5rem;
    font-weight: 500;
  }
}
