@import "src/styles/variables";
.Menu {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transform-origin: top left;
  overflow: hidden;
  contain: content;
  border-radius: 3px;
  //

  will-change: transform, box-shadow;
  animation-duration: 200ms;
  animation-timing-function: step-end;
  &.MenuExpanded {
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.4);
  }
  &.MenuThemeLight {
    background: #fff;
  }
  &.MenuThemeDark {
    background: #414042;
    color: white;
  }
}
.MenuContents {
  transform-origin: top left;
  will-change: transform;
  contain: content;
  animation-duration: 200ms;
  animation-timing-function: step-end;
}
.MenuToggle {
  text-align: left;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
  width: 100%;
}
.menuItemTitle {
  // color: $secondary;
  font-size: 18px;
}
// .menuItemTitle:hover {
//   color: #FF671D;
//   box-shadow: 0 3px #FF671D;
// }
.MenuActive {
  opacity: 1;
  pointer-events: auto;
  * {
    user-select: none;
  }
}
.MenuExpanded {
  animation-name: menuExpandAnimation;
}
.MenuContentsExpanded {
  animation-name: menuExpandContentsAnimation;
}
.MenuCollapses {
  animation-name: menuCollapseAnimation;
}
.MenuContentsCollapsed {
  animation-name: menuCollapseContentsAnimation;
}

.MenuItem {
  composes: MenuItem from "../TopBar/Menu.module.scss";
  .MenuThemeDark & {
    color: white;
  }
}
.Bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
