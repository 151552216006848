.FooterContainer {
    background-color: black;
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    bottom: 0;
    left: 0;
    font-size: 1rem;
    position: absolute;
    width: 100%;
}