//@import "~typeface-montserrat/index.css";
@import "./styles/functions";
@import "./styles/variables";

@import "~bootstrap/scss/bootstrap.scss";
@import "~@material/elevation/dist/mdc.elevation.css";

.btn,
.btn-lg {
  border-radius: 3rem;
  font-size: 13px;
}

h6 {
  font-size: 1rem;
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 980px !important;
  }
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 980px !important;
  }
}

.form-control {
  // height: initial !important;
  // padding: .3125rem 1rem;
}
.form-control::placeholder {
  font-size: $font-size-base * 0.95;
}
label.form-label {
  color: darken($primary, 15%);
  font-weight: 500;
}
.badge {
  font-weight: 500;
  font-size: 1em;
  padding-top: 0.3rem;
  padding-bottom: 0.25rem;
}

a.no-link {
  @include no-link;
}
.flex-grow-fix {
  position: relative;
  > * {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.custom-ui {
  background: white;
  border-radius: 7px;
}

.react-confirm-alert-overlay {
  z-index: 999;
  background: rgba(132, 132, 132, 0.9) !important;
}

.sw-100 {
  width: 100px !important;
}

//STRIPE
.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819eff;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.ElementsApp,
.ElementsApp .InputElement {
  color: #585050;
}

.ElementsApp .InputElement.is-invalid {
  color: #eb1c26;
}
// End of STRIPE

//MUI

div.MuiInputBase-formControl {
  border-radius: 8px;
}

label.MuiFormLabel-filled,
label.Mui-focused {
  font-weight: bold;
}

input.MuiInputBase-input {
  padding: 16.5px 14px !important;
}

.form-group > div.MuiFormControl-root > label.MuiOutlinedInput-input {
  font-size: 1em;
}

span.MuiButton-label {
  font-weight: bold;
  font-size: 13px;
}

button.MuiButton-root {
  padding: 11px;
  border-radius: 5px;
  background: #FF671D!important;
  // background: linear-gradient(
  //   90deg,
  //   rgba(225, 41, 122, 1) 23%,
  //   rgba(255, 188, 221, 1) 100%
  // ) !important;
  background: #FF671D;
  color: white;
}

button.MuiButton-root:disabled {
  padding: 11px;
  border-radius: 8px;
  background: #f075ad !important;
}

div.MuiOutlinedInput-input {
  padding: 16.5px 14px;
}

button.MuiButton-root:disabled {
  color: #acabab;
  background: linear-gradient(90deg, #c2c2c2 23%, #ebebeb 100%) !important;
}

//End of MUI

//Custome
.href-link {
  color: #3A42FF !important;
  font-weight: bold !important;
}

.text-grey {
  color: #afafaf;
}

.text-bold {
  font-weight: bold;
}

.alert-danger {
  border-radius: 8px !important;
}

.custom-badge {
  display: inline-flexbox;
  align-items: center;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: .9;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: $secondary;
  // margin-top: -.2rem;
}

.react-datepicker__input-container {
  & > input {
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #c9c9c9;

  }
}

.fhr_s_draft {
  background-color:  #585050;
  color: white;
  padding: 4px;
  border-radius: 8px;
}

.fhr_s_submitted {
  background-color:  $primary;
  padding: 4px;
  color: white;
  border-radius: 8px;
}

.fhr_s_approved {
  background-color: green;
  padding: 4px;
  color: white;
  border-radius: 8px;
}

.fhr_s_rejected {
  background-color: #eb1c26;
  padding: 4px;
  color: white;
  border-radius: 8px;
}

// Internal App
.AppBody {
  padding: 1rem 1rem;
  @include iphone {
    padding: 0rem;
  }

  &.AppBreadcrumb {
    padding-top: .5rem !important;
    padding-bottom: 0rem !important;
  }
  
  .Card {
    background-color: $internal-white;
    margin-bottom: 1rem;
    border: none;
    border-radius: .375rem;
    box-shadow: $internal-box-shadow;
    &.WithBreadcrumb {
      margin-bottom: 0 !important;
    }

    .CardHeader {
      background-color: inherit;
      border-radius: .375rem .375rem 0 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
    .BgLight {
      background-color: white;//$internal-lighter-bg;
      border-radius: 0 0 .375rem .375rem;
    }
  }
  .PageTitle {
    margin-bottom: 0 !important;
  }
}

.slick-custom {
  li .react-aspect-ratio-placeholder {
    min-height: 0 !important;
  }
  .slick-active {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border: 2px solid $primary;
      border-radius: .625rem;
    }
    filter: grayscale(0%);
  }
}

.AppFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  @include iphone {
    padding: 1rem;
    text-align: center;
  }
}

.css-49v479 {
  width: 80%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  color: #3B3B3B;
  background: #FFFFFF;
  border: 1.06px solid var(--text-gray-2, #D9D9D9);
  div {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
}

@include iphone {
  .form-group,
  .input-group {
    > .form-control {
      background-color: $grey;
      border-width: 0;
      border-radius: $mobile-input-border-radius;
    }
  }
}

@import "./styles/rippleEffect";
