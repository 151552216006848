.NavBar {
  overflow-y: visible !important;
  height: 6.25rem;
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(156, 156, 156, 0.5);
  > :global(.navbar-brand) {
    flex-grow: 1;
  }
  display: flex;
  justify-content: space-between;
}
.supportLine {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #D9D9D9;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.supportLineLeft {
  width: 50%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
}
.supportLineRight {
  width: 25%;
  display: flex;
  justify-content: flex-start;
  margin-right: 0.5rem;
}
.supportLineItem {
  margin-right: 20px;
}
.NavBarDark {
  background: #414042;
}
.TopBarMenu {
  margin-right: 1rem;
}
.Logo {
  margin-left: 80px;
}
.RightIcon {
  margin-right: 4rem;
}
.topBarMenuItem {
  display: flex;
  align-items: center;
}
.btnHome {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 18px;
  min-width: 70px;
  margin-right: 10px;
  cursor: pointer;
}
.btnHomeItem:hover {
  color: #FF671D;
  box-shadow: 0 3px #FF671D;
}