@import "src/styles/variables";

body {
	overflow: inherit !important;
	// overflow-y: overlay !important;
  &::-webkit-scrollbar {
    width: .5rem;
  }
  &::-webkit-scrollbar-thumb {
    border: 5px solid #66666675;
    // border-radius: 10rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    border: 5px solid #22222275;
  }
  &::-webkit-scrollbar-track {
    position: absolute;
    right: -3rem;
    top: -50rem;
    background: transparent;
  }
}

.DecoratedInputGroup {
  :global(.input-group-text) {
    background-color: unset;
    border-right: 0;
    padding-right: 0 !important;
    padding-left: 1.25rem !important;
    svg {
      width: 1rem;
    }
  }
  :global(.form-control) {
    border-left: 0;
    background-color: unset;
  }
  &:global(.input-group-lg),
  :global(.input-group-lg) {
    :global(.form-control) {
      border-top-right-radius: calc(1.25rem + 1px);
      border-bottom-right-radius: calc(1.25rem + 1px);
    }
    :global(.input-group-text) {
      border-top-left-radius: calc(1.25rem + 1px);
      border-bottom-left-radius: calc(1.25rem + 1px);
    }
  }
}
.DecoratedInputGroupGrey {
  * {
    border-width: 0 !important;
  }
  :global(.form-control) {
    border-left: 0;
    background-color: $grey;
    border-top-right-radius: $mobile-input-border-radius;
    border-bottom-right-radius: $mobile-input-border-radius;
  }
  :global(.input-group-text) {
    background-color: $grey;
    border-right: 0;
    padding-right: 0 !important;
    padding-left: 1.25rem !important;
    svg {
      width: 1rem;
    }
    border-top-left-radius: $mobile-input-border-radius;
    border-bottom-left-radius: $mobile-input-border-radius;
  }
  &:global(.input-group-lg),
  :global(.input-group-lg) {
    :global(.form-control) {
      border-top-right-radius: calc(1.25rem + 1px);
      border-bottom-right-radius: calc(1.25rem + 1px);
    }
    :global(.input-group-text) {
      border-top-left-radius: calc(1.25rem + 1px);
      border-bottom-left-radius: calc(1.25rem + 1px);
    }
  }
}
.QooeeLogoBig {
  width: 240px;
}
.ItemWithSelectedDot {
  position: relative;
  &.ItemSelected:before {
    $dotSize: 6px;
    position: absolute;
    top: calc(50% - #{$dotSize/2});
    left: 1rem;
    content: "";
    visibility: visible;
    display: block;
    background: $primary;
    height: $dotSize;
    width: $dotSize;
    border-radius: 50%;
  }
}
.MobileTab {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  svg {
    height: 25px;
  }
}
.BadgeOutline {
  border-width: 1px;
  background-color: transparent !important;
  border-style: solid;
  padding: {
    left: 0.7em;
    right: 0.7em;
  }
  border-radius: 0.5em;
}
